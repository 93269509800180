import React from "react"
const AltSiteName = " - Sandfords"

export const sort = (a, b, sortType = "asc") => {
  if (a === null || a === undefined) {
    return 1
  }
  if (b === null || b === undefined) {
    return -1
  }
  if (sortType === "asc") {
    return a - b
  } else {
    return b - a
  }
}

export { AltSiteName }
