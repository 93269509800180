import React from "react"
import { Container } from "react-bootstrap"
import "./BannerNoBg.scss"
import parse from "html-react-parser"
import GetLink from "../functions/GetLink"
import SiteBreadcrumbs from "../Breadcrumb/site-breadcrumbs"

const BannerNoBg = ({ title, content, ctas, page }) => {
  const isLanding = page !== "areaguide"
  return (
    <>
      <SiteBreadcrumbs
        style="landing-breadcrumbs"
        alias={page === "areaguide"?"area-guides":""}
        menulabel={title}
      />

      <Container
        className={`banner-nobg-wrapper ${isLanding ? "landing-page" : ""}`}
      >
        <div className="banner-nobg-container">
          {page === "areaguide" ? (
            <>
              <h1 className="title">
                {title} <em>Area Guide</em>
              </h1>
              <div className="description">{parse(content)}</div>
            </>
          ) : (
            <div className="landing-page-content">
              <div className="content">{parse(content)}</div>
            </div>
          )}

          {ctas && (
            <div className="ctas">
              {ctas.map((cta, i) => (
                <GetLink
                  className="btn btn-primary-outline"
                  label={cta.CTA_Label}
                  link={cta.CTA_Link}
                  key={`noBgBannerCTA${i}`}
                />
              ))}
            </div>
          )}
        </div>
      </Container>
    </>
  )
}

export default BannerNoBg
