import React, { useState } from "react"
import { Accordion, Container } from "react-bootstrap"
import parse from "html-react-parser"
import GetGatsbyImage from "../common/site/get-gatsby-image"

import "./AreaguideDetails.scss"
import DetailPageGetInTouch from "../DetailPageGetInTouch/DetailPageGetInTouch"
import PlayVideo from "../PlayVideo/PlayVideo"

const AreaguideDetails = ({ areaguide }) => {
  const contents = areaguide?.Content
  const [isPlay, setPlay] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);

  return (
    <Container className="areaguide-details-section">
      <div className="img-section banner-img">
        {/* <img src={areaguide?.Banner_Image?.url} alt="areaguide detail image" /> */}
        <GetGatsbyImage
          image={areaguide?.Banner_Image}
          fallbackalt={"areaguide-detail-banner"}
        />
        {areaguide?.Video_Url && <a className="btn-play" onClick={() => {setPlay(true); setVideoUrl(areaguide?.Video_Url)}}><i className="icon icon-play"></i></a>}
      </div>

      {contents?.map((content, i) => (
        <>
          {content.__typename === "GLSTRAPI_ComponentComponentsImageModule" &&
            content.Image && (
              <div className="img-section">
                <GetGatsbyImage
                  image={content.Image}
                  fallbackalt={`areaguide detail image - ${i}`}
                />
                {/* <img src={content.Image?.url} alt="areaguide detail image" /> */}
                {content?.Video_Url && <a className="btn-play" onClick={() => {setPlay(true); setVideoUrl(content?.Video_Url)}}><i className="icon icon-play"></i></a>}
              </div>
            )}

          {content.__typename ===
            "GLSTRAPI_ComponentComponentsAreaguideTextContent" &&
            content.Text && (
              <div className="text-section">{parse(content.Text)}</div>
            )}

          {content.__typename ===
            "GLSTRAPI_ComponentComponentsAccordionModule" && (
            <div className="accordian-section">
              <Accordion flush>
                {content.Accordian_List?.map((accordion, i) => (
                  <Accordion.Item eventKey={i} key={"acoordian" + i}>
                    <Accordion.Header>
                      <h4>{accordion.List_Title}</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      {parse(accordion.List_Content)}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          )}

          {content.__typename === "GLSTRAPI_ComponentComponentsGetInTouch" && (
            <DetailPageGetInTouch content={content} />
          )}
        </>
      ))}
      {isPlay && <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={videoUrl}
          htmlink={""}
        />}
    </Container>
  )
}

export default AreaguideDetails
