import { graphql } from "gatsby"
import React from "react"
import BannerNoBg from "../components/BannerNoBg/BannerNoBg"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/global.scss"
import Header from "../components/Header/Header"
import AreaguideDetails from "../components/AreaguideDetails/AreaguideDetails"
import Footer from "../components/Footer/Footer"
import SEO from "../components/Seo/seo"
import FullWidthBlueBanner from "../components/SearchBanner/FullwidthBlueBanner"

const AreaguideDetailPage = ({ data }) => {
  const areaguide = data.glstrapi.areaguide
  return (
    <div className="areaguide-detail-wrapper">
      <SEO
        title={
          areaguide.Meta_Title
            ? areaguide.Meta_Title
            : areaguide.Areaguide_Title+" Area Guide"
        }
        description={areaguide.Meta_Description ? areaguide.Meta_Description : `Know more about ${areaguide.Areaguide_Title} here. Contact one of our estate agents for assistance in finding the right properties for you in ${areaguide.Areaguide_Title}.` }
      />
      <Header />
      <BannerNoBg
        title={areaguide.Areaguide_Title}
        content={areaguide.Areaguide_Intro}
        ctas={areaguide.Intro_CTA}
        page={"areaguide"}
      />
      <AreaguideDetails areaguide={areaguide} />
      <FullWidthBlueBanner type="getintouch" />
      <Footer />
    </div>
  )
}

export default AreaguideDetailPage

export const pageQuery = graphql`
  query GetAreaGuide($articleId: ID!) {
    glstrapi {
      areaguide(id: $articleId, publicationState: LIVE) {
        Areaguide_Intro
        Areaguide_Title
        Banner_Image {
          url
          alternativeText
          url_sharp {
            childImageSharp {
              gatsbyImageData(
                formats: WEBP
                width: 920
                height: 560
                quality: 80
                layout: FIXED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              )
            }
          }
        }
        Content {
          ... on GLSTRAPI_ComponentComponentsAreaguideTextContent {
            __typename
            id
            Text
          }
          ... on GLSTRAPI_ComponentComponentsImageModule {
            __typename
            id
            Video_Url
            Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 920
                    height: 560
                    quality: 80
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsAccordionModule {
            __typename
            id
            Accordian_List {
              List_Content
              List_Title
              id
            }
          }
          ... on GLSTRAPI_ComponentComponentsGetInTouch {
            __typename
            id
            Get_In_Touch_BG
            Show_Get_In_Touch
          }
        }
        Intro_CTA {
          CTA_Label
          CTA_Secondary_Link
          id
          CTA_Link {
            Label
            Primary_URL
            Secondary_URL
            Sorting
            Show_In_Top_Nav
            id
          }
        }
        Meta_Title
        Meta_Description
        URL
        id
        imagetransforms
        Tile_Image {
          url
        }
        Video_Url
      }
    }
  }
`
