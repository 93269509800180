import { Link } from "gatsby"
import React from "react"
import GetLink from "../functions/GetLink"
import "./DetailPageGetInTouch.scss"

const DetailPageGetInTouch = ({ content }) => {
  if (!content && !content.Show_Get_In_Touch) return null

  const isBlueBg = content.Get_In_Touch_BG === "Blue"

  return (
    <div className={`gitd-wrapper ${isBlueBg ? "gitd-blue-bg" : ""}`}>
      <div className="gitd-container">
        <div className="content">
          <h3 className="title">
            <em>Get in touch</em> with Sandfords
          </h3>
          <p className="description">
            Visit our office or call{" "}
            <a href="tel:+44 (0)20 7723 9988">+44 (0)20 7723 9988</a>
          </p>
        </div>
        <Link
          to="/contact-estate-agents-in-london/"
          className={`btn ${
            isBlueBg ? "btn-white-outline" : "btn-primary-outline"
          }`}
        >
          contact us
        </Link>
      </div>
    </div>
  )
}

export default DetailPageGetInTouch
