import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import "./assets/styles/_index.scss"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import GetLink from "../functions/GetLink"
const FullWidthBlueBanner = props => {
  const data = useStaticQuery(graphql`
    query getModuleData {
      glstrapi {
        globalConfig {
          Get_In_Touch_Module {
            Module_Content
            Module_CTA {
              CTA_Label
              CTA_Link {
                Primary_URL
              }
            }
          }
          Lets_Find_Property_Module {
            Module_Content
            Module_CTA {
              CTA_Label
              CTA_Link {
                Primary_URL
              }
            }
          }
          Are_You_Ready_To_Sell_Module {
            Module_Content
            Module_CTA {
              CTA_Label
              CTA_Link {
                Primary_URL
              }
            }
          }
        }
      }
    }
  `)

  let moduledata = ""
  if (props.type === "letus") {
    moduledata = data.glstrapi.globalConfig.Lets_Find_Property_Module
  } else if (props.type === "areyou_ready") {
    moduledata = data.glstrapi.globalConfig.Are_You_Ready_To_Sell_Module
  } else if (props.type === "getintouch") {
    moduledata = data.glstrapi.globalConfig.Get_In_Touch_Module
  }
  return (
    <section className="search-banner-wrapper full-width-blue-banner">
      <Container>
        <Row className="d-flex justify-content-center text-center">
          <Col xl={8} className="search-banner-container">
            {parse(moduledata.Module_Content)}
            {moduledata.Module_CTA && (
              <div className="search-banner-group-search d-flex justify-content-center col-lg-8 mx-auto">
                <GetLink
                  className={`btn btn-fill-primary `}
                  label={moduledata.Module_CTA.CTA_Label}
                  link={moduledata.Module_CTA.CTA_Link}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FullWidthBlueBanner
